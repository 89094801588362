import React from 'react';
import classes from './Header.module.css';
import { CgMenu } from 'react-icons/cg';
import { RiCloseLine } from 'react-icons/ri';

export const Header = (props) => {
	const activeHamburger = props.activeHamburger;
	const setActiveHamburger = props.setActiveHamburger;
	return (
		<div className={classes.fixedHeader}>
			<div className={classes.smallJactLogo}></div>
			{/* mobile view */}
			{!activeHamburger ? (
				<div className={classes.wrapperHamburger}>
					<CgMenu
						className={classes.hamburger}
						size='40px'
						color='white'
						onClick={() => setActiveHamburger(!activeHamburger)}
					/>
				</div>
			) : (
				<div className={classes.wrapperHamburger}>
					<RiCloseLine
						className={classes.hamburger}
						size='40px'
						color='white'
						onClick={() => setActiveHamburger(!activeHamburger)}
					/>
				</div>
			)}
			{activeHamburger && (
				<div className={classes.mobileLinks}>
					<a
						className={classes.mobileLink}
						href='#welcome'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						WELCOME
					</a>
					<a
						className={classes.mobileLink}
						href='#adventure'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						MISSION & VISION
					</a>
					<a
						className={classes.mobileLink}
						href='#network'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						JOIN THE NETWORK
					</a>
					<a
						className={classes.mobileLink}
						href='#prospect'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						PROSPECT SESSION
					</a>
					<a
						className={classes.mobileLink}
						href='#lectures'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						BOOK US
					</a>
					<a
						className={classes.mobileLink}
						href='#qAndA'
						onClick={() => setActiveHamburger(!activeHamburger)}>
						Q&A
					</a>
				</div>
			)}

			{/* desktop view */}
			<div className={classes.links}>
				<a className={classes.link} href='#welcome'>
					WELCOME
				</a>
				<a className={classes.link} href='#adventure'>
					MISSION & VISION
				</a>
				<a className={classes.link} href='#network'>
					JOIN THE NETWORK
				</a>
				<a className={classes.link} href='#prospect'>
					PROSPECT SESSION
				</a>
				<a className={classes.link} href='#lectures'>
					BOOK US
				</a>
				<a className={classes.link} href='#qAndA'>
					Q&A
				</a>
			</div>
		</div>
	);
};
